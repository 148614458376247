import React, { useState, useEffect } from "react";
import { Contract } from '@ethersproject/contracts';
import { Row, Col, Tooltip } from "antd";
import './index.css';

var _ = require('lodash');

function Gallery(props) {
    let [tokens, setTokens] = useState();
    let [imgSvgs, setImgSvgs] = useState();

    useEffect(() => {
        let nftContract = new Contract(props.nftAddress, props.nftAbi, props.library)
        let tokenContract = new Contract(props.tokenAddress, props.tokenAbi, props.library)

        async function getTokens() {
            var newTokens = await nftContract.walletOfOwner(props.account)
            newTokens = newTokens.map((val) => { return val.toNumber() })
            newTokens =  _.sortBy(newTokens)
            setTokens(newTokens)
        }

        getTokens();
    }, [props]);

    useEffect(() => {
        let nftContract = new Contract(props.nftAddress, props.nftAbi, props.library)

        async function getImages() {
            var allSvgs = []
            for(var tkn_id of tokens) {
                try {
                    var rawUri = await nftContract.tokenURI(tkn_id)
                    var decodedUri = atob(rawUri.split(',')[1])
                    var encodedSvg = JSON.parse(decodedUri)['image']
                    allSvgs.push({
                        'token_id': tkn_id,
                        'img_svg': encodedSvg
                    })
                } catch {
                    allSvgs.push({
                        'token_id': tkn_id,
                        'img_svg': ''
                    })
                }
            }
            setImgSvgs(allSvgs)
        }

        if(tokens) {
            getImages()
        }
    }, [props, tokens])

    if(tokens && imgSvgs) {
        return (
            <div>
                <Row>
                    {
                        imgSvgs.map((imgObj) => {
                            return (
                                <Tooltip key={imgObj['token_id']} title={imgObj['token_id']} placement="bottom">
                                    <Col key={imgObj['token_id']} span={8}>
                                        <img className='gallery-img' src={imgObj['img_svg']} alt="NFT" />
                                    </Col>
                                </Tooltip>

                            )
                        })
                    }
                </Row>
            </div>
        )
    } else {
        return(
            <p>Loading...</p>
        )
    }
}

export default Gallery
