import React from "react";
import { Contract } from '@ethersproject/contracts';
import { useContractFunction } from '@usedapp/core';
import { Input } from "antd";

const { Search } = Input;

function BurnButton(props) {
    let nftContract = new Contract(props.nftAddress, props.nftAbi, props.library)
    const { state, send } = useContractFunction(nftContract, 'burnForMint', { transactionName: 'Burn for mint' })

        
    const onBurnPressed = async (input) => {
        let regex = /[0-9]*/

        if (regex.test(input)) {
            send(input, {gasLimit: 500000})
        }
        return
    };

    return (
        <Search placeholder='ID to burn' onSearch={onBurnPressed} enterButton="Burn for mint" disabled={props.disabled} />
    )

}

export default BurnButton
