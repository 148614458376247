import React from "react";
import { Contract } from '@ethersproject/contracts';
import { useContractFunction } from '@usedapp/core';
import { Button } from "antd";

let APPROVE_AMOUNT = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

function ApproveTokenButton(props) {
    let tokenContract = new Contract(props.tokenAddress, props.tokenAbi, props.library)

    const { state, send } = useContractFunction(
        tokenContract,
        'approve'
    )

    const onApprovePressed = async () => {
        send(props.nftAddress, APPROVE_AMOUNT)
        return
    };

    return (
        <Button id="approveTokenButton" onClick={onApprovePressed}>
            Approve Token
        </Button>
    )
}

export default ApproveTokenButton
