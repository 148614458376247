import React from "react";
import { Contract } from '@ethersproject/contracts';
import { useContractFunction } from '@usedapp/core';
import { Button } from "antd";

function UnstakeAllButton(props) {
    let tokenContract = new Contract(props.tokenAddress, props.tokenAbi, props.library)
    const { state, send } = useContractFunction(tokenContract, 'unstakeAll', { transactionName: 'Unstake all' })

    const onUnstakePressed = async () => {
        send({})
        return
    };

    return (
        <Button id="unstakeAllButton" onClick={onUnstakePressed} disabled={props.disabled}>
            Unstake All
        </Button>
    )
}

export default UnstakeAllButton
