import React from "react";
import { Contract } from '@ethersproject/contracts';
import { useContractFunction } from '@usedapp/core';
import { Input } from "antd";

const { Search } = Input;

function StakeButton(props) {
    let tokenContract = new Contract(props.tokenAddress, props.tokenAbi, props.library)
    const { state, send } = useContractFunction(tokenContract, 'stakeByIds', { transactionName: 'Stake by ids' })

    const onStakePressed = async (input) => {
        let regex = /[0-9]+(,[0-9]+)*/g

        if(regex.test(input)) {
            input = input.split(',')
            send(input)
        }
        return
    };

    return (
        <Search placeholder='IDs to stake' onSearch={onStakePressed} enterButton="Stake Token IDs" disabled={props.disabled}/>
    )
}

export default StakeButton
