import React from "react";
import { ethers } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { useContractFunction } from '@usedapp/core';
import { Button } from "antd";

function MintButton(props) {
    let nftContract = new Contract(props.nftAddress, props.nftAbi, props.library)
    const { state, send } = useContractFunction(nftContract, 'mintNfToken', { transactionName: 'Mint for ETH' })

    const onMintPressed = async () => {
        let qty = 1
        let txnValue = ethers.utils.parseUnits(((props.price * qty).toFixed(8)).toString(), 'ether')
        send(qty, { value: txnValue, gasLimit: 350000*qty})
        return
    };

    return (
        <Button className='btn' id="mintButton" onClick={onMintPressed} disabled={true}>
            Mint with ETH
        </Button>
    )
}

export default MintButton
