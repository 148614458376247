import React, { useState, useEffect } from "react";
import { ethers } from 'ethers';
import { useEtherBalance } from '@usedapp/core';
import { Contract } from '@ethersproject/contracts';
import { Row, Col } from "antd";
import { ReactComponent as FallbackMoose } from '../../assets/fallbackMoose.svg';
import './index.css';

import ApproveTokenButton from '../ApproveTokenButton/index.js';
import MintButton from '../MintButton/index.js';
import MintForTokenButton from '../MintForTokenButton/index.js';

function Mint(props) {
    let [price, setPrice] = useState();
    let [ethBalance, setEthBalance] = useState();
    let [tokenPrice, setTokenPrice] = useState();
    let [tokenBalance, setTokenBalance] = useState();
    let [allowance, setAllowance] = useState();
    let [imgSvg, setImgSvg] = useState();
    var balance = useEtherBalance(props.account)

    useEffect(() => {
        let nftContract = new Contract(props.nftAddress, props.nftAbi, props.library)
        let tokenContract = new Contract(props.tokenAddress, props.tokenAbi, props.library)

        async function getPrice() {
            var newPrice = await nftContract.currentPrice()
            newPrice = ethers.utils.formatUnits(newPrice, 'ether')
            setPrice(parseFloat(newPrice))
        }

        async function getTokenBalance() {
            var newTokenBalance = await tokenContract.balanceOf(props.account)
            newTokenBalance = ethers.utils.formatUnits(newTokenBalance, 'ether')
            setTokenBalance(parseFloat(newTokenBalance).toFixed(2))
        }

        async function getTokenPrice() {
            var newTokenPrice = await nftContract.currentTokenCost()
            newTokenPrice = ethers.utils.formatUnits(newTokenPrice, 'ether')
            setTokenPrice(parseFloat(newTokenPrice))
        }

        async function getAllowance() {
            var newAllowance = await tokenContract.allowance(props.account, props.nftAddress)
            newAllowance = ethers.utils.formatUnits(newAllowance, 'ether')
            setAllowance(parseFloat(newAllowance))
        }

        async function getImage() {
            try {
                var rawUri = await nftContract.hashTo(0)
                var decodedUri = atob(rawUri.split(',')[1])
                var encodedSvg = JSON.parse(decodedUri)['image']
                setImgSvg(encodedSvg)
            } catch {
                setImgSvg('')
            }
        }

        async function getBalance() {
            let newEthBalance = ethers.utils.formatUnits(balance, 'ether')
            setEthBalance(parseFloat(newEthBalance))
        }

        if (typeof balance !== 'undefined') {
            getPrice()
            getBalance()
            getTokenPrice()
            getTokenBalance()
            getAllowance()
            getImage()
        }
    }, [props, balance])

    console.log(Date.now())
    let loaded = (ethBalance >= 0) && (price >= 0) && (tokenPrice >= 0) && (tokenBalance >= 0) && (allowance >= 0)
    if(loaded) {
        return (
            <Row>
                <Col span={24}>
                    {
                        imgSvg ?
                            <img className='mint-img' src={imgSvg} alt="NFT" />
                        :
                            <FallbackMoose className='mint-img' />
                    }
                    <p className='moose-img-text'>
                        * This is a sample Moose.
                    </p>
                    <p className='moose-img-text'>
                        Newly minted Moose will have a unique set of traits.
                    </p>
                </Col>
                <Col span={12}>
                    <p className='mintRow'>Mint with ETH</p>
                    <p> {Math.min(price, ethBalance)}  / {price} ETH</p>
                    <MintButton
                        {...props}
                        price={price}
                        disabled={ethBalance < price || Date.now() < 1633964400000}
                    />
                </Col>
                <Col span={12}>
                    <p className='mintRow'>Mint with TRAX</p>
                    <p> {Math.min(tokenPrice, tokenBalance)}  / {tokenPrice} TRAX</p>
                    {allowance >= tokenPrice ?
                        <MintForTokenButton
                            {...props}
                            tokenPrice={tokenPrice}
                            disabled={tokenBalance < tokenPrice || Date.now() < 1633964400000}
                        />
                        :
                        <ApproveTokenButton
                            {...props}
                        />
                    }
                </Col>
            </Row>
        )
    } else {
        return(
            <p>Loading...</p>
        )
    }
}

export default Mint
