import React, { useState, useEffect } from "react";
import { ethers } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { Row, Col } from "antd";
import './index.css';

import ClaimButton from '../ClaimButton/index.js';

function Account(props) {
    let [rewards, setRewards] = useState();
    let [itemBalance, setItemBalance] = useState();
    let [tokenBalance, setTokenBalance] = useState();
    let [staked, setStaked] = useState();

    useEffect(() => {
        let nftContract = new Contract(props.nftAddress, props.nftAbi, props.library)
        let tokenContract = new Contract(props.tokenAddress, props.tokenAbi, props.library)

        async function getItemBalance() {
            var newItemBalance = await nftContract.balanceOf(props.account)
            newItemBalance = newItemBalance.toNumber()
            setItemBalance(newItemBalance)
        }

        async function getTokenBalance() {
            var newTokenBalance = await tokenContract.balanceOf(props.account)
            newTokenBalance = ethers.utils.formatUnits(newTokenBalance, 'ether')
            setTokenBalance(parseFloat(newTokenBalance).toFixed(2))
        }

        async function getStaked() {
            var newStaked = await tokenContract.getTokensStaked(props.account)
            setStaked(newStaked)
        }

        async function getRewardsBalance() {
            var newRewards = await tokenContract.getAllRewards(props.account)
            newRewards = ethers.utils.formatUnits(newRewards, 'ether')
            setRewards(parseFloat(newRewards))
        }
        
        getItemBalance()
        getTokenBalance()
        getStaked()
        getRewardsBalance()
    }, [props])

    let loaded = (rewards >= 0) && staked && (itemBalance >= 0) && (tokenBalance >= 0)
    if(loaded) {
        return (
            <Row>
                <Col span={24} className="card-title">
                    <p className='wallet-title'>Wallet</p>
                </Col>
                <Col span={24}>
                    <p className='wallet'>
                        {itemBalance} MOOSE
                    </p>
                    <p className='wallet'>
                        {tokenBalance} TRAX
                    </p>
                </Col>
                <Col span={24} className="card-title">
                    <p className='wallet-title'>Staking</p>
                </Col>

                <Col span={24}>
                    <p className='wallet'>
                        {staked.length} MOOSE
                    </p>
                    <p className='wallet'>
                        {rewards.toFixed(6)} unclaimed TRAX
                    </p>
                    <ClaimButton
                        {...props}
                        amount={rewards}
                        disabled={rewards === 0}
                    />                
                </Col>
            </Row>
        )
    } else {
        return(
            <p>Loading...</p>
        )
    }
}

export default Account
