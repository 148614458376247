import React, { useState, useEffect } from "react";
import { Contract } from '@ethersproject/contracts';
import { Row, Col } from "antd";
import './index.css';

function Status(props) {
    let [supply, setSupply] = useState();
    let [burned, setBurned] = useState();
    let [staked, setStaked] = useState();

    useEffect(() => {
        let nftContract = new Contract(props.nftAddress, props.nftAbi, props.library)

        async function getSupply() {
            var newSupply = await nftContract.totalSupply()
            newSupply = newSupply.toNumber()
            setSupply(newSupply)
        }

        async function getBurned() {
            var newBurned = await nftContract.walletOfOwner(props.burnAddress)
            newBurned = newBurned.length
            setBurned(newBurned)
        }

        async function getStaked() {
            var newStaked = await nftContract.walletOfOwner(props.tokenAddress)
            newStaked = newStaked.length
            setStaked(newStaked)
        }

        getSupply()
        getBurned()
        getStaked()
    }, [props])

    let loaded = (supply >= 0) && (burned >= 0)
    if(loaded) {
        return (
            <Row>
                <Col span={6}>
                    <p className='status-value'> {supply} / 10,000 </p>
                    <p className='status-title'>Minted</p>
                </Col>
                <Col span={6}>
                    <p className='status-value'> {staked} </p>
                    <p className='status-title'>Staked</p>
                </Col>
                <Col span={6}>
                    <p className='status-value'> {supply - burned - staked} </p>
                    <p className='status-title'>In wallets</p>
                </Col>
                <Col span={6}>
                    <p className='status-value'> {burned} </p>
                    <p className='status-title'>Burned</p>
                </Col>
            </Row>
        )
    } else {
        return(
            <p>Loading...</p>
        )
    }
}

export default Status
