import React, { useEffect, useState } from "react";
import { useEthers } from '@usedapp/core';
import { Layout, Button, Card, Tooltip, Row, Col } from "antd";
import { FaDiscord, FaMediumM, FaTwitter } from 'react-icons/fa';
import './App.css';

// app components
import Status from "./components/Status/index.js";
import Mint from "./components/Mint/index.js";
import Account from "./components/Account/index.js";
import Staking from "./components/Staking/index.js";
import Gallery from "./components/Gallery/index.js";

import rawNftAbi from "./abis/NfToken.json";
import rawTokenAbi from "./abis/Token.json";

const { Header, Content, Footer } = Layout;

// static variables

let NFT_ADDRESS = '0x3146Dd9c200421A9c7d7b67bd1b75Ba3e2c15310';
let TOKEN_ADDRESS = '0xc92E730EeBaCA3d16d6C17F7F4646DcE923663e8';
let BURN_ADDRESS = '0x000000000000000000000000000000000000dEaD';

function App(props) {
  const [nftAddress, setNftAddress] = useState()
  const [tokenAddress, setTokenAddress] = useState()
  const [burnAddress, setBurnAddress] = useState()
  const [nftAbi, setNftAbi] = useState(null)
  const [tokenAbi, setTokenAbi] = useState(null)
  const [block, setBlock] = useState()
  const { activateBrowserWallet, account, library, chainId } = useEthers()

  useEffect(() => {
    setNftAddress(NFT_ADDRESS)
    setTokenAddress(TOKEN_ADDRESS)
    setBurnAddress(BURN_ADDRESS)
    setNftAbi(rawNftAbi)
    setTokenAbi(rawTokenAbi)
  }, [])

  useEffect(() => {
    async function onBlock() {
      setBlock(library.blockNumber)
    }

    if(library) {
      library.on('block', onBlock)
    }
  }, [library])

  
  let loaded = block && nftAddress && tokenAddress && burnAddress && nftAbi && tokenAbi && chainId
  return (
    <Layout className='layout'>
      <Header id='header'>
        <Tooltip title={"Connected"} placement="left" disabled={typeof account === undefined}>
          <Button id='connect-btn' className={typeof account !== undefined ? '' : ''} disabled={false} onClick={() => activateBrowserWallet()}>
            {account ? <p>Account: {account}</p> : "Connect"}
          </Button>
        </Tooltip>
      </Header>
      {
        chainId !== 1 ?
          <Content className='content'>
            <Row className='content-row'>
              <Col span={12} offset={6}>
                <Card className='card rounded'>
                  <p id='wrong-network'>Connect to Ethereum Mainnet to continue.</p>
                </Card>
              </Col>
            </Row>
          </Content>
        :
          loaded ?
            <Content className='content'>
              <Row className='content-row'>
                <Col span={12} offset={6}>
                  <Row>
                    <Col span={12} className='content-left'>
                      <Row>
                        <Col span={24}>
                          <Card className='card title-card rounded'>
                            <p id='main-title'>
                              Moose Trax
                            </p>
                            <p id='main-title-icons'>
                              <a className='icon' href='https://discord.gg/DpZG3f4Fkb'>
                                <FaDiscord />
                              </a>
                              <a className='icon' href='https://medium.com/@moosetraxnft'>
                                <FaMediumM />
                              </a>
                              <a className='icon' href='https://twitter.com/MooseTraxNFT'>
                                <FaTwitter />
                              </a>
                            </p>
                          </Card>
                        </Col>
                        <Col span={24}>
                          <Card className='card rounded'>
                            {account ?
                              <Account account={account} library={library} block={block} burnAddress={burnAddress} nftAddress={nftAddress} tokenAddress={tokenAddress} nftAbi={nftAbi} tokenAbi={tokenAbi} />
                              : <p>Connect wallet to continue</p>
                            }
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} className='content-right'>
                      <Card className='card rounded'>
                        {account ?
                          <Mint account={account} library={library} block={block} burnAddress={burnAddress} nftAddress={nftAddress} tokenAddress={tokenAddress} nftAbi={nftAbi} tokenAbi={tokenAbi} />
                          : <p>Connect wallet to continue</p>
                        }
                      </Card>
                    </Col>
                  </Row>
                  <Row className='content-row'>
                    <Col span={24}>
                      <Card className='card rounded'>
                        {account ?
                          <Status account={account} library={library} block={block} burnAddress={burnAddress} nftAddress={nftAddress} tokenAddress={tokenAddress} nftAbi={nftAbi} tokenAbi={tokenAbi} />
                          : <p>Connect wallet to continue</p>
                        }
                      </Card>
                    </Col>
                  </Row>
                  <Row className='content-row'>
                    <Col span={24}>
                      <Card className='card'>
                        {account ?
                          <Staking account={account} library={library} block={block} burnAddress={burnAddress} nftAddress={nftAddress} tokenAddress={tokenAddress} nftAbi={nftAbi} tokenAbi={tokenAbi} />
                          : <p>Connect wallet to continue</p>
                        }
                      </Card>
                    </Col>
                  </Row>
                  <Row className='content-row'>
                    <Col span={24}>
                      <Card className='card rounded'>
                        {account ?
                          <Gallery account={account} library={library} block={block} burnAddress={burnAddress} nftAddress={nftAddress} tokenAddress={tokenAddress} nftAbi={nftAbi} tokenAbi={tokenAbi} />
                          : <p>Connect wallet to continue</p>
                        }
                      </Card>
                    </Col>
                  </Row>

                </Col>
              </Row>
            </Content>
          :
            <Content className='content'>
              <p>Loading...</p>
            </Content>
      }
      <Footer id='footer'>
        <p>
          Contracts:
          <a href='https://etherscan.io/address/0x3146dd9c200421a9c7d7b67bd1b75ba3e2c15310'> MOOSE </a>
          <a href='https://etherscan.io/address/0xc92e730eebaca3d16d6c17f7f4646dce923663e8'>TRAX</a>
        </p>
        <p>
          Disclaimer: Moose Trax is not responsible for any loss of funds incurred by users of this site.
          Use at your own risk, and do your own research before interacting with these smart contracts.
        </p>
      </Footer>
    </Layout>
  )
}

export default App;

