import React from "react";
import { Contract } from '@ethersproject/contracts';
import { useContractFunction } from '@usedapp/core';
import { Button } from "antd";
import './index.css';

function ClaimButton(props) {
    let tokenContract = new Contract(props.tokenAddress, props.tokenAbi, props.library)
    const { state, send } = useContractFunction(tokenContract, 'claimAll', { transactionName: 'Claim all' })

    const onClaimPressed = async () => {
        send({})
        return
    };

    return (
        <Button className='claim-button' onClick={onClaimPressed} disabled={props.disabled}>
            {
                props.amount > 0 ?
                    "Claim"
                :
                    "Stake to earn TRAX"
            }
            
        </Button>
    )
}

export default ClaimButton
