import React, { useState, useEffect } from "react";
import { Contract } from '@ethersproject/contracts';
import { Collapse, Row, Col } from "antd";
import './index.css';

import ApproveItemButton from '../ApproveItemButton/index.js';
import BurnButton from '../BurnButton/index.js';
import StakeButton from '../StakeButton/index.js';
import UnstakeButton from '../UnstakeButton/index.js';
import UnstakeAllButton from '../UnstakeAllButton/index.js';
var _ = require('lodash');

const { Panel } = Collapse;

function Staking(props) {
    let [tokens, setTokens] = useState();
    let [approved, setApproval] = useState();

    useEffect(() => {
        let nftContract = new Contract(props.nftAddress, props.nftAbi, props.library)
        let tokenContract = new Contract(props.tokenAddress, props.tokenAbi, props.library)

        async function getTokens() {
            var newStaked = await tokenContract.getTokensStaked(props.account)
            var accountTokens = await nftContract.walletOfOwner(props.account)
            
            accountTokens = accountTokens.map((val) => { return val.toNumber() })
            newStaked = newStaked.map((val) => { return val.toNumber() })

            var newTokens = {
                'owned': _.sortBy(accountTokens),
                'staked': _.sortBy(newStaked)
            }
            setTokens(newTokens)
        }

        async function getApproval() {
            var newApproval = await nftContract.isApprovedForAll(props.account, props.tokenAddress)
            setApproval(newApproval)
        }

        getTokens();
        getApproval();
    }, [props]);

    if(tokens && (typeof approved !== 'undefined')) {
        return (
            <div>
                <Row>
                    <Col span={24}>
                        <Collapse className='collapse' ghost>
                            <Panel header={tokens.owned.length + ' Owned tokens'} key="2" collapsible={tokens.owned.length === 0 ? 'disabled' : ''}>
                                <p>
                                    {
                                        tokens.owned.map((ret, ind) => {
                                            return ind === tokens.owned.length - 1 ? ret : ret + ','
                                        })
                                    }
                                </p>
                            </Panel>
                            <Panel header={tokens.staked.length + ' Staked tokens'} key="1" collapsible={tokens.staked.length === 0 ? 'disabled' : ''}>
                                <p>
                                    {
                                        tokens.staked.map((ret, ind) => {
                                        return ind === tokens.staked.length - 1 ? ret : ret + ','
                                        })
                                    }
                                </p>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <Row>
                    <Col span={12} offset={6}>
                        <BurnButton
                            disabled={tokens.owned.length === 0}
                            {...props}
                        />
                    </Col>
                </Row>
                <Row className='btn-row'>
                    <Col span={12}>
                        <ApproveItemButton
                            disabled={tokens.owned.length === 0}
                            {...props}
                        />
                    </Col>
                    <Col span={12}>
                        <UnstakeAllButton
                            disabled={tokens.staked.length === 0}
                            {...props}
                        />
                    </Col>
                </Row>
                <Row className='btn-row'>
                    <Col className='btn' span={12}>
                        <StakeButton
                            disabled={tokens.owned.length === 0 || tokens.staked.length === 10 || !approved}
                            {...props}
                        />
                    </Col>
                    <Col className='btn' span={12}>
                        <UnstakeButton
                            disabled={tokens.staked.length === 0}
                            {...props}
                        />
                    </Col>
                </Row>
            </div>
        )
    } else {
        return(
            <p>Loading...</p>
        )
    }
}

export default Staking
