import React from "react";
import { Contract } from '@ethersproject/contracts';
import { useContractFunction } from '@usedapp/core';
import { Button } from "antd";

function MintForTokenButton(props) {
    let nftContract = new Contract(props.nftAddress, props.nftAbi, props.library)
    const { state, send } = useContractFunction(nftContract, 'mintWithToken', { transactionName: 'Mint for token' })

    const onMintPressed = async () => {
        send({ gasLimit: 450000 })
        return
    };

    return (
        <Button className='btn' id="mintForTokenButton" onClick={onMintPressed} disabled={true}>
            Mint with TRAX
        </Button>
    )
}

export default MintForTokenButton
