import React, { useState, useEffect } from "react";
import { Contract } from '@ethersproject/contracts';
import { useContractFunction } from '@usedapp/core';
import { Button } from "antd";

function ApproveItemButton(props) {
    let [approval, setApproval] = useState();

    useEffect(() => {
        let nftContract = new Contract(props.nftAddress, props.nftAbi, props.library)

        async function getApproval() {
            var newApproval = await nftContract.isApprovedForAll(props.account, props.tokenAddress)
            setApproval(newApproval)
        }

        getApproval()
    }, [props])

    let nftContract = new Contract(props.nftAddress, props.nftAbi, props.library)
    const { state, send } = useContractFunction(
        nftContract,
        'setApprovalForAll'
    )

    const onApproveItemPressed = async () => {
        send(props.tokenAddress, true)
        return
    };

    if(typeof approval != 'undefined') {
        return (
            <Button id="approveItemButton" onClick={onApproveItemPressed} disabled={approval || props.disabled}>
                Approve All
            </Button>
        )
    } else {
        return (
            <p>Loading...</p>
        )
    }
}

export default ApproveItemButton
